import React, { useState } from 'react';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { LockerInfo } from 'components/common/LockerInfo';
import { colors } from 'config/colors';
import api from 'helpers/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eye-icon.svg'
import CustomToast from 'components/common/customToast';
import { useEffect } from 'react';
import { changeLanguage } from 'redux/actions/language';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

export const Delivery = ({ step, setStep, pin, setPin, phoneNumber, setPhoneNumber }) => {
  const [locker, setLocker] = useState({});
  const intl = useIntl();
  const [showPin, setShowPin] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const dispatch = useDispatch()
  const recaptchaRef = React.createRef();

  // const submitPin = () => {
  //   recaptchaRef.current.reset()
  //   recaptchaRef.current.executeAsync().then((token) => {
  //     let my_captcha_response = window.grecaptcha.getResponse()
  //     if (my_captcha_response) {
  //       api.get(`api/delivery/verifyPickUpPackageWithQr?pin=${pin}&phone=${phoneNumber}&locationId=${process.env.REACT_APP_LOCATION_ID}`)
  //         .then(function (response) {
  //           console.log(response);
  //           if (response.data.ResultCode === 1) {
  //             setLocker(response.data.Data.assignedLocker);
  //             setStep(2);
  //             setShowToast(false)
  //           }
  //           else {
  //             setShowToast(true)
  //             if (response.data?.Error === "L31") {
  //               setLocationError(intl.formatMessage({ id: 'app.locationError' }))
  //             }
  //             else {
  //               setLocationError(false)
  //             }
  //           }
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //     } else {
  //       console.log("não aceite")
  //     }
  //   });
  // };

  const submitPin = () => {
    api.get(`api/delivery/qrcode/verify?pin=${pin}&phone=${phoneNumber}&locationId=${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response.data.Result) {
          setLocker(response.data.Data.locker);
          setStep(2);
          setShowToast(false)
        }
        else {
          setShowToast(true)
          if (response.data?.Error === "L31") {
            setLocationError(intl.formatMessage({ id: 'app.locationError' }))
          }
          else {
            setLocationError(false)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const openLocker = () => {
    api.get(`api/delivery/qrcode/pickUp?pin=${pin}&phone=${phoneNumber}&locationId=${process.env.REACT_APP_LOCATION_ID}`, {
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
      }
    })
      .then(function (response) {
        console.log(response);
        if (response.data.Result) {
          setStep(3);
          setPin('');
          setPhoneNumber('');
          setShowToast(false)
          setTimeout(() => {
            setStep(1);
            dispatch(changeLanguage('pt-PT'))
          }, 3000)
        }
        else {
          setShowToast(true)
          if (response.data?.Error === "L31") {
            setLocationError(intl.formatMessage({ id: 'app.locationError' }))
          }
          else {
            setLocationError(false)
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const isPinValid = () => {
    const pattern = /\d{6}/;

    if (pin.match(pattern)) return true;
    return false;
  };

  const isPhoneNumberValid = () => {
    const pattern = /\d{9}/;

    if (phoneNumber.match(pattern)) return true;
    return false;
  };

  const handleChangePin = (e) => {
    setPin(e.target.value);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  useEffect(() => {
    const timeOutToast = setTimeout(() => {
      setShowToast(false)
    }, [3000])
    return () => clearTimeout(timeOutToast);
  }, [, showToast])

  if (step === 1) return (
    <>
      <div className='position-absolute m-0 p-0 px-2' style={{ height: "fit-content", width: "calc(100% - 1.5rem)", top: 20 }}>
        <div className='position-relative w-100 h-100 m-0 p-0'>
          <CustomToast showToast={showToast} setShowToast={setShowToast} error text={locationError ? locationError : false} />
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
        <span style={{ fontSize: "1.6em" }} className="my-2"><FormattedMessage id="app.title" /></span>
        <span className="my-2 mx-1" style={{ fontSize: "1.1em" }}><FormattedMessage id="app.insertNumberPinSms" /></span>
        <input
          type="text"
          className="w-100 mt-3 mb-2 input"
          style={{ padding: 12 }}
          placeholder={intl.formatMessage({ id: 'app.insertNumber' })}
          value={phoneNumber}
          inputMode="numeric"
          maxLength="9"
          onChange={handleChangePhoneNumber}
        />
        <div className='position-relative h-auto position-relative w-100 m-0 p-0 my-2'>
          <input
            type={showPin ? "text" : "password"}
            style={{ padding: 12 }}
            className="w-100 input position-relative"
            placeholder={intl.formatMessage({ id: 'app.insertPin' })}
            value={pin}
            maxLength="6"
            inputMode="numeric"
            onChange={handleChangePin}
          />
          <div className='m-0 p-0 position-absolute' style={{ bottom: 14, right: 14 }}>
            <EyeIcon className='m-0 p-0' style={{ height: 12, width: "auto" }} onClick={() => setShowPin(!showPin)} />
          </div>
        </div>
        <PrimaryButton
          text={intl.formatMessage({ id: 'app.confirm' })}
          onClick={submitPin}
          color="green"
          fontColor="white"
          className="w-100 mt-2"
          isEnabled={isPinValid() && isPhoneNumberValid()}
        />
        {/* <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          ref={recaptchaRef}
          size="invisible"
          style={{ display:"none"}}
        /> */}
      </div>
    </>
  );
  else if (step === 2) return (
    <>
      <div className='position-absolute m-0 p-0 px-2' style={{ height: 32.5, width: "calc(100% - 1.8rem)", top: 20 }}>
        <div className='position-relative w-100 h-100 m-0 p-0'>
          <CustomToast showToast={showToast} setShowToast={setShowToast} error />
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
        <LockerInfo text="app.findYourPackage" locker={locker} />
        <PrimaryButton text={intl.formatMessage({ id: 'app.openLocker' })} isEnabled onClick={openLocker} color="green" fontColor="white" className="w-100 mt-2" />
      </div>
    </>
  );
  else return (
    <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
      <LockerInfo text="app.lockerOpen" locker={locker} />
    </div>
  );
};