import React from 'react';
import { Row, Col } from 'reactstrap';
import logo from 'assets/images/logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from 'config/colors';
import { changeLanguage } from 'redux/actions/language';

export const TopNav = ({ step, setStep, setPhoneNumber, setPin }) => {

  const locale = useSelector(state => state.language);
  const dispatch = useDispatch();

  const resetStep = () => {
    if (step !== 1) {
      setStep(1)
      setPhoneNumber('')
      setPin('')
    }
  }

  return (
    <Row className="h-100">
      <Col xs="6" className='h-100'>
        <Row className='h-100 ps-3 d-flex align-items-center'>
          <img src={logo} style={{ height: 35 }} className="p-0 m-0 w-auto" onClick={() => resetStep()} />
        </Row>
      </Col>
      <Col xs="6" className="p-0 h-100">
        <Row className='m-0 h-100 pe-3 d-flex align-items-center justify-content-end'>
          <div className='m-0 p-0 d-flex w-auto h-auto'>
            <div className='m-0 me-1 h-auto w-auto d-flex justify-content-center align-items-center'
              style={{
                border: locale === "pt-PT" ? `1px solid ${colors.green}` : `1px solid ${colors.fontPrimary}`,
                background: locale === "pt-PT" ? colors.green : colors?.white,
                borderRadius: "6px",
                color: locale === "pt-PT" ? colors.white : colors?.fontPrimary,
                padding: 7
              }}
              onClick={() => dispatch(changeLanguage('pt-PT'))}
            >
              <span className='m-0 p-0' style={{ fontSize: "1.1em", lineHeight: 1 }}>PT</span>
            </div>
            <div className='m-0 mx-1 h-auto w-auto d-flex justify-content-center align-items-center'
              style={{
                border: locale === "en-US" ? `1px solid ${colors.green}` : `1px solid ${colors.fontPrimary}`,
                background: locale === "en-US" ? colors.green : colors?.white,
                borderRadius: "6px",
                color: locale === "en-US" ? colors.white : colors?.fontPrimary,
                padding: 7
              }}
              onClick={() => dispatch(changeLanguage('en-US'))}
            >
              <span className='m-0 p-0' style={{ fontSize: "1.1em", lineHeight: 1 }}>EN</span>
            </div>
            <div className='ms-1 h-auto w-auto d-flex justify-content-center align-items-center'
              style={{
                border: locale === "es-ES" ? `1px solid ${colors.green}` : `1px solid ${colors.fontPrimary}`,
                background: locale === "es-ES" ? colors.green : colors?.white,
                borderRadius: "6px",
                color: locale === "es-ES" ? colors.white : colors?.fontPrimary,
                padding: 7
              }}
              onClick={() => dispatch(changeLanguage('es-ES'))}
            >
              <span className='m-0 p-0' style={{ fontSize: "1.1em", lineHeight: 1 }}>ES</span>
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
};